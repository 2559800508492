function entityFields() {
  return {
    name: '',
    type: {
      items: [],
    },
    tags: {
      items: [],
    },
    data: {
      legalName: {
        value: '',
      },
      isActive: {
        value: true,
      },
      isKYC: {
        value: "",
        color: '',
      },
      isFiatCurrencyTrading: {
        value: '',
        items: [{ name: 'Yes' }, { name: 'No' }],
      },
      isPrivacyCoinsSupported: {
        value: "",
        items: [{ name: 'Yes' }, { name: 'No' }],
      },
      website: {
        value: [
          {
            key: '',
            link: '',
          },
        ],
      },
      parentCompany: {
        value: '',
      },
      domicileCountry: {
        value: '',
        icon: '',
        context: [],
        items: [],
      },
      status: {
        value: '',
        color: '',
      },
      description: {
        value: '',
      },
      officeAddress: {
        value: '',
      },
      screenshots: {
        value: [],
      },
      providedServices: {
        value: '',
      },
      primaryOperationalRegions: {
        value: [],
        items: [],
      },
      restrictedJurisdictions: {
        value: [],
        items: [],
      },
      languages: {
        value: [],
        items: [],
      },
      socialNetworkLinks: {
        value: [
          {
            key: '',
            link: '',
          },
        ],
      },
      regulatoryCompliance: [],
      banks: [],
      paymentSystems: [],
      contacts: [],
      contactsDepartments: [],
    },
  }
}

function regulatoryComplianceFields() {
  return {
      country: '',
      localAuthority: '',
      licenseNumber: '',
      licenseLink: '',
      licenseType: '',
      registeredName: '',
      dates: {
        from: '',
        to: '',
      },
      status: '',
    }
}
function banksFields() {
  return {
    country: '',
    bankName: '',
    holder: '',
    accountNumber: '',
    BIC: '',
    IBAN: '',
    }
}
function paymentSystemsFields() {
  return {
    logo: '',
    systemName: '',
    paymentType: '',
    paymentMethods: '',
    website: '',
    registrationGeography: {
      value: []
    },
  }
}
function contactsFields() {
  return {
    contactName: '',
    position: '',
    emailAddress: '',
    contactLinks: [
      {
        key: '',
        link: '',
      },
    ],
    }
}
function contactsDepartmentsFields() {
  return {
    departmentName: '',
    departmentLinks: [
      {
        key: '',
        link: '',
      },
    ],
    }
}

export { entityFields, regulatoryComplianceFields, banksFields, contactsFields, paymentSystemsFields, contactsDepartmentsFields }